import styled from 'styled-components';
import CloseSvg from '../../../../assets/builder_images/close.svg'
import { AppSettingsType, AppModes } from '../../../../providers/GameProvider.types';

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div<{ $appMode: AppModes, $appSettings: AppSettingsType }>`
  background-color: #2D1804;
  height: 80%;
  max-width: 460px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.$appSettings.bg_dark_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 44px 10px 0px;
  border-radius: 20px;

  @media (max-width: 480px) {
    border-radius: 0px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }
`;

export const Decor = styled.div<{ $appMode: AppModes }>`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: url('/game/builder/${props => props.$appMode}/lead_decor.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`

export const CloseButton = styled.div<{ $isWebView: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: all 0.1s ease-out;
  z-index: 99;
  &:hover {
    opacity: 0.8;
  }
  ${props => props.$isWebView && `
    top: 60px;
  `}
`;


export const Title = styled.span<{ $isWebView: boolean; $appSettings: AppSettingsType }>`
  text-align: center;
  font-family: ${props => props.$appSettings.font_family ?? "Noticia Text"};
  font-size: 24px;
  font-style: normal;
  font-weight: ${props => props.$appSettings.font_weight ?? 700};
  line-height: normal;
  background: linear-gradient(180deg, #FFEAA2 0%, #DB8307 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  margin-bottom: 5px;
  cursor: default;
  ${props => props.$isWebView && `
    margin-top: 80px;
  `}

  @media (max-width: 480px) {
    margin-top: 44px;
  }
  `;

export const SubTitle = styled.span`
  color:  white;
  text-align: center;
  font-family: "Noticia Text";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  cursor: default;
  user-select: none;
  -webkit-user-select: none;
  margin-bottom: 30px;
`;

export const TableWrapper = styled.div<{ $appSettings: AppSettingsType }>`
  cursor: default;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${props => props.$appSettings.text_color};
  }
`

export const Table = styled.div`
  font-family: 'Noticia Text';
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: white;
  user-select: none;
  -webkit-user-select: none;
`

export const Row = styled.div<{ $backImg?: string; $appMode: AppModes; $appSettings: AppSettingsType }>`
  padding: 15px;
  display: flex;
  border-radius: 8px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.10);
  div:not(.coin) {
    &:first-child {
      margin-left: 10px;
      margin-right: 20px;
      color: ${props => props.$appSettings.text_color};
      min-width: 40px;
      height: 50px;
      background-image: url(${props => props.$backImg});
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &:nth-child(2) {
      text-align: left;
      flex-grow: 1;
      display: flex;
      align-items: center;
      margin-left: 20px;
      @media (max-width: 380px) {
        margin-left: 0px;
      }
    }
    &:last-child {
      display: flex;
      align-items: center;
      margin: 0;
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      div {
        margin-right: 5px;
        margin-top: 5px;
      }
    }
  }
  &.selected {
    div:first-child {
      color: ${props => props.$appSettings.main_color};
    }
    border: 1px solid ${props => props.$appSettings.main_color};
  }

  &:nth-child(1) {
    position: relative;
    div {
      &:first-child {
        color: #4C3400;
      }
    }
  }
  &:nth-child(2) {
    position: relative;

    div {
      &:first-child {
        color: #514B47;
      }
    }
  }
  &:nth-child(3) {
    position: relative;
    div {
      &:first-child {
        color: #572710;
      }
    }
  }
`


export const Coin = styled.div<{ $appMode: AppModes }>`
  width: 16px;
  height: 16px;
  background-size: cover;
  background-image: url('/game/builder/${props => props.$appMode}/coin.webp');
`;


export const TableHeader = styled.div<{ $appSettings: AppSettingsType }>`
  cursor: default;
  width: 100%;
  border-bottom: 1px solid ${props => props.$appSettings.main_color};
  color: ${props => props.$appSettings.text_color};
  font-family: 'Noticia Text';
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  user-select: none;
  -webkit-user-select: none;
  display: flex;
  div {
    padding: 10px;
    &:nth-child(1) {
      margin: 0 10px;
      width: 60px;
    }
    &:nth-child(2) {
      text-align: left;
      flex-grow: 1;
    }
    &:nth-child(3) {
      margin-right: 25px;
    }
  }
`
