import styled from 'styled-components';
import { LandingConfigType } from '../../utils/types';

export const Logo = styled.img`
  height: 34px;
`;

export const TopTag = styled.div<{ $config: LandingConfigType['topTag'] }>`
  border: 1px solid ${(props) => props.$config.borderColor};
  background-color: ${(props) => props.$config.backgroundColor};
  color: ${(props) => props.$config.textColor};
  font-style: ${(props) => props.$config.fontStyle};
  font-family: Avenir Next;
  border-radius: 100px;
  padding: 8px 38px;
  font-size: 18px;
  font-weight: 700;
  width: max-content;
  margin: 20px auto;
`;

export const MiddleBlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    margin: 0;
  }
`;

export const TextBlock1 = styled.p<{
  $config: LandingConfigType['textBlock1'];
}>`
  font-family: Avenir Next;
  font-size: ${(props) => props.$config.fontSize};
  font-style: ${(props) => props.$config.fontStyle};
  color: ${(props) => props.$config.textColor};
  font-weight: 700;
  text-align: center;
`;

export const TextBlock2 = styled.p<{
  $config: LandingConfigType['textBlock2'];
}>`
  font-family: Avenir Next;
  font-size: ${(props) => props.$config.fontSize};
  font-style: ${(props) => props.$config.fontStyle};
  color: ${(props) => props.$config.textColor};
  font-weight: 700;
  text-align: center;
`;

export const TextBlock3 = styled.p<{
  $config: LandingConfigType['textBlock3'];
}>`
  font-family: Avenir Next;
  font-size: ${(props) => props.$config.fontSize};
  font-style: ${(props) => props.$config.fontStyle};
  color: ${(props) => props.$config.textColor};
  font-weight: 700;
  text-align: center;
`;

export const TextBlock4 = styled.div<{
  $config: LandingConfigType['textBlock4'];
}>`
  font-family: Avenir Next;
  font-size: ${(props) => props.$config.fontSize};
  font-style: ${(props) => props.$config.fontStyle};
  color: ${(props) => props.$config.textColor};
  font-weight: 500;
  text-align: center;
  padding: 0 12px 0;
  margin-top: 20px;
`;

export const Button = styled.button<{
  $config: LandingConfigType['getBonusButton'];
}>`
  border: none;
  cursor: pointer;
  background: ${(props) => props.$config.buttonColor};
  border-radius: 24px;
  font-family: Avenir Next;
  font-size: ${(props) => props.$config.buttonFontSize};
  font-weight: 700;
  color: ${(props) => props.$config.textColor};
  max-width: max-content;
  width: initial;
  padding: 16px 30px;
  margin: 20px auto 0;

  &:hover {
    opacity: 0.8;
  }

  &:disabled {
    opacity: 0.8;
  }
`;

export const BottomImage = styled.div<{ $bgImage: string }>`
  background-image: url(${(props) => props.$bgImage});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 375px;
  height: 219px;
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1554px;
  width: 100%;
  margin: 0 auto;
  padding: 30px 20px 30px;
`;

export const Wrapper = styled.div<{ $config: LandingConfigType['background'] }>`
  background-color: ${(props) => props.$config.color};
  background-image: url(${(props) => props.$config.mobileImageURL});
  background-size: cover;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
  min-height: 100vh;
  background-position: bottom;

  @media (min-width: 1536px) {
    ${BlockWrapper} {
    }
  }

  & * {
    box-sizing: border-box;
  }

  @media (min-width: 1000px) {
    flex-direction: row;
    padding: 0;
    background-image: url(${(props) => props.$config.desktopImageURL});
    background-position: center;

    ${MiddleBlockWrapper} {
      margin-bottom: 36px;
    }

    ${BlockWrapper} {
      align-items: flex-start;
      justify-content: space-between;
      min-height: 100vh;
    }
    ${Logo} {
      height: 58px;
    }
    ${TopTag} {
      font-size: 30px;
      margin: 20px 0;
    }
    ${BottomImage} {
      min-width: 653px;
      min-height: 382px;
    }
    ${TextBlock1}, ${TextBlock2}, ${TextBlock3} {
      font-size: 71px;
      width: max-content;
    }

    ${TextBlock4} {
      font-size: 16px;
      padding: 0;
      margin: 0;
    }

    ${Button} {
      width: initial;
      margin: 20px 0;
      padding: 20px 90px;
    }
  }
`;
