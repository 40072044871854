export function numberWithCommas(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function getSlotsRandomNumber() {
  return Math.ceil(Math.random() * 6)
}

export function getPlinkoRandomNumber() {
  return Math.floor(Math.random() * (165 - 155 + 1) + 155)
}
