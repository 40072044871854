import { BuilderSpinner } from './SlotsSpinner';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { useMemo, useRef } from 'react';
import { getSlotsRandomNumber } from '../../../../utils/number';
import { Box, useMediaQuery } from '@mui/material';

export function BuilderSlotsMachine() {
  const isLess900 = useMediaQuery('(max-height:900px)');
  const isMobile = useMediaQuery('(max-width:480px)');
  const { onFinish, config, roundNumber, onStart, isRolling, isWinningRound, appMode, appSettings } = useGameState();
  let matches: number[] = [];
  let _spinner1 = useRef<BuilderSpinner | null>(null);
  let _spinner2 = useRef<BuilderSpinner | null>(null);
  let _spinner3 = useRef<BuilderSpinner | null>(null);

  const finishHandler = (value: number) => {
    matches.push(value);
    if (matches.length === 3) {
      const totalMatches = matches.reduce((acc, result, resultIndex, arr) => {
        let howManyMatches = 0;
        matches?.forEach((el, elIndex) => {
          if (el === result && elIndex !== resultIndex) {
            howManyMatches += 1;
          }
        });

        return howManyMatches > acc ? howManyMatches : acc;
      }, 0);

      let userWinAmount = config.prizes[totalMatches];

      onFinish(totalMatches > 0, userWinAmount);
    }
  };

  const handleClick = () => {
    onStart();
    matches = [];
    if (_spinner1 && _spinner2 && _spinner3) {
      _spinner1.current?.spin();
      _spinner2.current?.spin();
      _spinner3.current?.spin();
    }

    // facebook and google Contact event
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'Contact');
    }
    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      gtag('event', 'Contact', { send_to: process.env.REACT_APP_GTAG_EVENTS_ID });
    }
  };

  const SPINNER_1 = useMemo(() => {
    const finishNumber = config.slotConfig
      ? config.slotConfig[roundNumber - 1]
        ? config.slotConfig[roundNumber - 1][0]
        : getSlotsRandomNumber()
      : 0;

    return (
      <BuilderSpinner
        appMode={appMode}
        onFinish={finishHandler}
        ref={_spinner1}
        finishNumber={finishNumber}
        timer={1000}
        isWinningRound={isWinningRound}
      />
    );
    // eslint-disable-next-line
  }, [roundNumber, config, isWinningRound, appMode]);

  const SPINNER_2 = useMemo(() => {
    const finishNumber = config.slotConfig
      ? config.slotConfig[roundNumber - 1]
        ? config.slotConfig[roundNumber - 1][1]
        : getSlotsRandomNumber()
      : 0;

    return (
      <BuilderSpinner
        appMode={appMode}
        onFinish={finishHandler}
        ref={_spinner2}
        finishNumber={finishNumber}
        timer={1400}
        isWinningRound={isWinningRound}
      />
    );
    // eslint-disable-next-line
  }, [roundNumber, config, isWinningRound, appMode]);

  const SPINNER_3 = useMemo(() => {
    const finishNumber = config.slotConfig
      ? config.slotConfig[roundNumber - 1]
        ? config.slotConfig[roundNumber - 1][2]
        : getSlotsRandomNumber()
      : 0;

    return (
      <BuilderSpinner
        appMode={appMode}
        onFinish={finishHandler}
        ref={_spinner3}
        finishNumber={finishNumber}
        timer={2200}
        isWinningRound={isWinningRound}
      />
    );
    // eslint-disable-next-line
  }, [roundNumber, config, isWinningRound, appMode]);

  return (
    <>
      <Box
        sx={{
          overflow: 'hidden',
          width: '320px',
          height: '306px',
          position: 'absolute',
          bottom: isLess900 ? 235 : undefined,
          top: isLess900 ? undefined : 360,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundImage: `url('/game/builder/${appMode}/slots_machine_back.webp')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          '&:after': {
            transition: 'all 0.5s linear',
            position: 'absolute',
            content: '""',
            display: 'block',
            width: '100%',
            height: '100%',
            top: 0,
            zIndex: 15,
            boxShadow:
              isWinningRound && !isRolling
                ? `0px -110px 20px -15px ${appSettings.bg_dark_color} inset, 0px 120px 20px -15px ${appSettings.bg_dark_color} inset`
                : undefined,
          },
        }}
      >
        <Box
          sx={{
            transform: 'translate(-50%, -50%)',
            position: 'absolute',
            top: '50%',
            left: '50%',
            display: 'flex',
            gap: '6px',
            zIndex: 9,
          }}
        >
          {SPINNER_1}
          {SPINNER_2}
          {SPINNER_3}
        </Box>
      </Box>
      <Box
        sx={{
          pointerEvents: isRolling ? 'none' : undefined,
          width: '120px',
          height: '100px',
          backgroundImage: isRolling
            ? `url('/game/builder/${appMode}/play_pushed.webp')`
            : `url('/game/builder/${appMode}/play.webp')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          cursor: 'pointer',
          position: 'absolute',
          bottom: isLess900 ? 40 : undefined,
          top: isLess900 ? undefined : 760,
          zIndex: 30,
          right: '50%',
          WebkitTapHighlightColor: 'transparent',
          transform: 'translateX(50%)',
          transition: 'all 0.3s ease',
          '&:hover': {
            opacity: isMobile ? 1 : 0.8,
          },
        }}
        onClick={isRolling ? undefined : handleClick}
      ></Box>
    </>
  );
}
