import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as BGAIcon } from '../../../assets/builder_images/begambleaware_org.svg';
import { useGameState } from '../../../providers/GameProvider.hooks';

function Disclaimer() {
  const { appMode, appSettings } = useGameState();
  const isLess393 = useMediaQuery('(max-width:393px)');

  return (
    <Box
      sx={{
        marginTop: '-30px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        gap: '15px',
        position: 'relative',
        zIndex: 20,
      }}
    >
      <Typography
        sx={{
          textAlign: 'center',
          fontFamily: 'Noticia Text',
          fontWeight: 400,
          color: appSettings.text_color,
          fontSize: '8px',
          maxWidth: isLess393 ? window.innerWidth - 40 : '355px',
          width: '100%',
          '& a': {
            color: appSettings.main_color,
          },
        }}
      >
        This website is designed exclusively for entertainment purposes. The games available on this site do not involve
        real money, bets, or winnings. All activities on this website are purely for fun and amusement. It's important
        to note that this website is intended for adult users (18+) and does not provide opportunities for "real money
        gambling" or the chance to win actual money or prizes. If you require assistance in managing your gambling
        activity responsibly, consider seeking support from{' '}
        <a target="_blank" href="https://www.kethea.gr/en/" rel="noreferrer">
          gambling therapy services
        </a>
        . {appSettings.app_name} maintains no responsibility for any losses, damages, or negative consequences that may
        arise from the use or misuse of the information or simulator provided on our website. Users are advised to
        engage responsibly, acknowledging the potential risks involved, including the development of addictive behavior.
        It is essential to approach the simulator with caution and awareness of its simulated nature, ensuring a mindful
        and enjoyable experience.
        <br />
        <br />
        Reliability-Security-Legality
        <br />
        We start with a basic and inviolable rule. The live casino you choose must operate with all legitimacy to keep
        you and your money Safe. We check the blacklist every day, which means that here you will find only the best
        live licensed online casinos. After you see that it is 100% legal, you pass to the next stage which is
        registration.
        <br />
        <br />
        Casino security assessment criteria
        <br />
        We use two basic methods of finding and evaluating online casinos. Initially, using years of experience and
        knowledge we search and analyze online casinos, betting and or poker rooms. After we have assessed the
        credibility of a casino by focusing on potential licenses, financial stability and reputation, we check the hard
        data. Because using algorithms to assess popularity levels, Software Quality, Payment Systems and so on. It is
        useful but not enough for a complete picture. But if combined with independent in-depth research, then the
        combination can yield very detailed analyses.
        <br />
        <br />
        Variety, security & speed of payment methods
        <br />
        The last, but equally key piece for the best live online casinos is variety. Both in the payment methods and in
        the list of live games. The many trading modes available "untie the hands of the players". It is extremely easy
        for customers of an online casino live to be able to deposit with both prepaid cards (paysafecard) and e-wallets
        (Skrill, Neteller) and bank cards (Visa, Mastercard, etc.) or via bank transfer. The same is true of modes of
        Ascension.
      </Typography>
      <Box
        sx={{
          '& svg': {
            path: {
              fill: appSettings.text_color,
            },
          },
        }}
      >
        <BGAIcon></BGAIcon>
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: '15px',
          justifyContent: 'center',
          '& span': {
            fontFamily: 'Noticia Text',
            color: appSettings.main_color,
            fontSize: '10px',
            lineHeight: 'normal',
            textDecoration: 'underline',
            fontWeight: 400,
            cursor: 'pointer',
          },
        }}
      >
        <span
          onClick={() => {
            window.open(`docs/${appMode}/privacy.html`, '_blank');
          }}
        >
          PRIVACY POLICY
        </span>

        <span
          onClick={() => {
            window.open(`docs/${appMode}/terms.html`, '_blank');
          }}
        >
          TERMS OF USE
        </span>
      </Box>
      <Typography
        sx={{
          fontFamily: 'Noticia Text',
          color: appSettings.text_color,
          fontSize: '8px',
          fontWeight: 400,
          marginBottom: '10px',
        }}
      >
        Copyright © 2024 {appSettings.app_name}
      </Typography>
    </Box>
  );
}

export default Disclaimer;
