import { useGameState } from '../../../../providers/GameProvider.hooks';
import { getCookie } from '../../../../utils/encrypt';
import { useEffect, useState } from 'react';
import { getGtagId } from '../../../../utils/gtag';
import { recordUserData } from '../../../../services/requests';
import { Box, Typography, useMediaQuery } from '@mui/material';
import { ReactComponent as CloseSvg } from '../../../../assets/builder_images/close.svg';
import mb4popover from '../../../../assets/builder_images/popover_image_mix4bet.webp';

export function Modal() {
  const { config, setShowModal, appMode, appSettings } = useGameState();
  const [isDisabled, setDisabled] = useState(false);
  const isMobile = useMediaQuery('(max-width:430px)');

  async function handleClaim() {
    if (isDisabled) {
      return;
    }
    setDisabled(true);

    const gtagSessionId = await getGtagId('session_id');
    const gtagClientId = await getGtagId('client_id');

    const utm_campaign = new URLSearchParams(window.location.search).get('utm_campaign');

    const body = {
      client_user_agent: window.navigator.userAgent,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
      timestamp: (Date.now() / 1000).toFixed(),
      action_source: config.link,
      project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      gtag_client_id: gtagClientId,
      gtag_session_id: gtagSessionId,
      ...(utm_campaign && { utm_campaign }),
    };

    await recordUserData(body)
      .then((res) => {
        const userId = res.result.id;

        const subId = [userId, process.env.REACT_APP_PIXEL_ID, process.env.REACT_APP_FIREBASE_PROJECT_ID].join('_');

        const link =
          config.link.trim() +
          '?' +
          new URLSearchParams({
            subId1: subId,
            utm_term: subId,
            utm_affid: process.env.REACT_APP_UTM_AFF_ID ?? '',
          }).toString();

        window.open(link, '_self');

        setDisabled(false);
      })
      .catch((error) => {
        console.log('Get unique id error', error);
        fetch(`${window.location.origin}/event`, {
          method: 'POST',
          body: JSON.stringify({
            userAgent: window.navigator.userAgent,
            event: 'recordUserData error',
            eventCategory: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            project: window.location.origin,
            time: Date.now(),
            body: body,
            error: JSON.stringify(error),
            timeDifference: Date.now() - Number(body.timestamp) * 1000,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).finally(() => {
          window.open(config.link.trim(), '_self');
          setDisabled(false);
        });
      });
  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1001,
        width: '100%',
        height: '100%',
        alignItems: isMobile ? 'end' : 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(3, 5, 7, 0.85)',
      }}
    >
      <Box
        sx={{
          maxWidth: '430px',
          width: '100%',
          height: isMobile ? '100%' : '620px',
          maxHeight: '100%',
          borderRadius: isMobile ? undefined : '20px',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        {!config.closeButtonHidden && (
          <CloseSvg
            style={{
              position: 'absolute',
              top: 20,
              right: 20,
              cursor: 'pointer',
            }}
            onClick={() => {
              setShowModal(false);
            }}
          />
        )}
        {config.m4bPopover ? (
          <>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                backgroundImage: `url(${mb4popover})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                flexGrow: 1,
              }}
            ></Box>

            <Box
              sx={{
                padding: '0px 20px 20px',
                backgroundColor: '#11044E',
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              {config.m4bTitle && (
                <Box
                  sx={{
                    backgroundColor: '#11044E',
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#11044E',
                      background:
                        'linear-gradient(89deg, #15F1ED -0.65%, #00F4E5 8.61%, #00F7DB 17.87%, #00FACF 27.13%, #00FCC2 36.39%, #1CFEB2 45.66%, #3FFFA2 54.92%, #59FF8F 64.18%, #70FF7C 73.44%, #86FF66 82.7%, #9CFF4E 91.96%, #B1FF31 101.22%)',
                      padding: '8px 20px',
                      borderRadius: '4px',
                      fontFamily: 'Barlow Condensed ExtraBold',
                      fontSize: '16px',
                      fontWeight: 800,
                      lineHeight: 'normal',
                      textAlign: 'center',
                      textTransform: 'uppercase',
                      userSelect: 'none',
                    }}
                  >
                    {config.m4bTitle}
                  </Typography>
                </Box>
              )}
              <Typography
                sx={{
                  maxWidth: 'calc(100% - 40px)',
                  color: '#F2B417',
                  fontFamily: 'Barlow Condensed ExtraBold',
                  fontSize: '40px',
                  fontWeight: appSettings.font_weight ?? 700,
                  lineHeight: 'normal',
                  textAlign: 'center',
                  userSelect: 'none',
                  textTransform: 'uppercase',
                }}
              >
                {config.titleText}
              </Typography>
              <Typography
                sx={{
                  maxWidth: 'calc(100% - 40px)',
                  color: '#FFF',
                  fontFamily: 'Barlow Condensed ExtraBold',
                  fontSize: '40px',
                  fontWeight: appSettings.font_weight ?? 700,
                  lineHeight: 'normal',
                  textAlign: 'center',
                  userSelect: 'none',
                  textTransform: 'uppercase',
                  marginBottom: '20px',
                }}
              >
                {config.subTitleText}
              </Typography>
              <Box
                id="modal-cashout-button"
                onClick={isDisabled ? undefined : handleClaim}
                sx={{
                  background: 'linear-gradient(0deg, #FF7C00 0%, #FFB300 49.74%, #FFE05C 100%)',
                  borderRadius: '40px',
                  height: '64px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontFamily: 'Barlow Condensed ExtraBold',
                  fontSize: '20px',
                  fontWeight: 800,
                  lineHeight: 'normal',
                  color: '#11044E',
                  textTransform: 'uppercase',
                  userSelect: 'none',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    opacity: isMobile ? 1 : 0.8,
                  },
                }}
              >
                {config.claimButtonText}
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                backgroundImage: `url('/game/builder/${appMode}/popover.webp')`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'bottom',
                flexGrow: 1,
              }}
            ></Box>
            <Box
              sx={{
                padding: '20px',
                backgroundColor: appSettings.bg_dark_color,
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  maxWidth: 'calc(100% - 40px)',
                  fontFamily: appSettings.font_family ?? 'Noticia Text',
                  fontSize: '32px',
                  fontWeight: appSettings.font_weight ?? 700,
                  lineHeight: 'normal',
                  textAlign: 'center',
                  userSelect: 'none',
                  background: 'linear-gradient(180deg, #FFEAA2 0%, #DB8307 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {config.titleText}
              </Typography>
              <Typography
                sx={{
                  fontFamily: appSettings.font_family ?? 'Noticia Text',
                  fontSize: '32px',
                  fontWeight: appSettings.font_weight ?? 700,
                  lineHeight: 'normal',
                  textAlign: 'center',
                  userSelect: 'none',
                  textShadow: '0px 2px 0px rgba(0, 0, 0, 0.40)',
                }}
              >
                {config.titleText}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Noticia Text',
                  fontSize: '20px',
                  fontWeight: 400,
                  lineHeight: 'normal',
                  color: '#fff',
                  marginTop: '10px',
                  marginBottom: '20px',
                  textAlign: 'center',
                  userSelect: 'none',
                }}
              >
                {config.subTitleText}
              </Typography>
              <Box
                id="modal-cashout-button"
                onClick={isDisabled ? undefined : handleClaim}
                sx={{
                  background: 'linear-gradient(0deg, #FF7C00 0%, #FFB300 49.74%, #FFE05C 100%)',
                  borderRadius: '40px',
                  height: '64px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  cursor: 'pointer',
                  fontFamily: appSettings.font_family ?? 'Noticia Text',
                  fontSize: '20px',
                  fontWeight: appSettings.font_weight ?? 700,
                  lineHeight: 'normal',
                  color: appSettings.bg_dark_color,
                  userSelect: 'none',
                  transition: 'all 0.3s ease',
                  '&:hover': {
                    opacity: isMobile ? 1 : 0.8,
                  },
                }}
              >
                {config.claimButtonText}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
}
