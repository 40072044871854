import * as S from './Landing.styles';

import { useState } from 'react';
import { LandingConfigType } from '../../utils/types';
import { recordUserData } from '../../services/requests';
import { getCookie } from '../../utils/encrypt';
import { getGtagId } from '../../utils/gtag';
import useEvents from '../../hooks/useEvents';
import { Logo } from './Landing.styles';

function Landing({ config }: { config: LandingConfigType }) {
  const [disabled, setDisabled] = useState(false);

  useEvents();

  const handleClick = async () => {
    if (disabled) {
      return;
    }

    setDisabled(true);

    const gtagSessionId = await getGtagId('session_id');

    const gtagClientId = await getGtagId('client_id');

    const utm_campaign = new URLSearchParams(window.location.search).get(
      'utm_campaign'
    );

    const body = {
      client_user_agent: window.navigator.userAgent,
      fbc: getCookie('_fbc'),
      fbp: getCookie('_fbp'),
      timestamp: (Date.now() / 1000).toFixed(),
      action_source: config.referral_link,
      project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      gtag_client_id: gtagClientId,
      gtag_session_id: gtagSessionId,
      ...(utm_campaign && { utm_campaign }),
    };

    recordUserData(body)
      .then((responseUserRecord) => {
        const mergedParameter = [
          responseUserRecord.result.id,
          process.env.REACT_APP_PIXEL_ID,
          process.env.REACT_APP_FIREBASE_PROJECT_ID,
        ].join('_');

        const referralUrl =
          config.referral_link.trim() +
          '?' +
          new URLSearchParams({
            subId1: mergedParameter,
            utm_term: mergedParameter,
            utm_affid: process.env.REACT_APP_UTM_AFF_ID ?? '',
          }).toString();

        window.open(referralUrl, '_self');
      })
      .catch((error) => {
        console.log('Get unique id error', error);

        fetch(`/event`, {
          method: 'POST',
          body: JSON.stringify({
            userAgent: window.navigator.userAgent,
            event: 'recordUserDataV2 error',
            eventCategory: process.env.REACT_APP_FIREBASE_PROJECT_ID,
            project: window.location.origin,
            time: Date.now(),
            body: body,
            error: JSON.stringify(error),
            timeDifference: Date.now() - Number(body.timestamp) * 1000,
          }),
          headers: {
            'Content-Type': 'application/json',
          },
        }).finally(() => {
          window.open(config.referral_link.trim(), '_self');
          setDisabled(false);
        });
      });
  };

  return (
    <S.Wrapper $config={config.background}>
      <S.BlockWrapper>
        {!config.topLogo.logoHidden ? (
          <Logo src={config.topLogo.logoURL} alt="logo" />
        ) : (
          <div></div>
        )}
        <S.MiddleBlockWrapper>
          {!config.topTag.topTagHidden && (
            <S.TopTag $config={config.topTag}>{config.topTag.text}</S.TopTag>
          )}
          {!config.textBlock1.textBlockHidden && (
            <S.TextBlock1 $config={config.textBlock1}>
              {config.textBlock1.text}
            </S.TextBlock1>
          )}
          {!config.textBlock2.textBlockHidden && (
            <S.TextBlock2 $config={config.textBlock2}>
              {config.textBlock2.text}
            </S.TextBlock2>
          )}
          {!config.textBlock3.textBlockHidden && (
            <S.TextBlock3 $config={config.textBlock3}>
              {config.textBlock3.text}
            </S.TextBlock3>
          )}
          {!config.getBonusButton.buttonHidden && (
            <S.Button
              disabled={disabled}
              onClick={disabled ? undefined : handleClick}
              $config={config.getBonusButton}
            >
              {config.getBonusButton.buttonText}
            </S.Button>
          )}
        </S.MiddleBlockWrapper>
        {!config.textBlock4.textBlockHidden ? (
          <S.TextBlock4 $config={config.textBlock4}>
            {config.textBlock4.text}
          </S.TextBlock4>
        ) : (
          <S.TextBlock4 $config={config.textBlock4}></S.TextBlock4>
        )}
      </S.BlockWrapper>
    </S.Wrapper>
  );
}

export default Landing;
