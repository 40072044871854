import styled from 'styled-components';
import CloseSvg from '../../../../assets/builder_images/close.svg'
import { AppSettingsType, AppModes } from '../../../../providers/GameProvider.types';

export const Wrapper = styled.div`
  display: flex;
  transition: all 0.3s ease-out;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  width: 100%;
  height: 100%;
  background-color: rgba(3, 5, 7, 0.85);
  justify-content: center;
  align-items: center;
`;

export const Modal = styled.div<{ $appMode: AppModes, $appSettings: AppSettingsType }>`
  background-color: #2D1804;
  height: 80%;
  max-width: 460px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: ${props => props.$appSettings.bg_dark_color};
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 44px 10px 0px;
  border-radius: 20px;

  @media (max-width: 480px) {
    border-radius: 0px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-width: 100%;
    padding: 0px;
  }
`;

export const CloseButton = styled.div<{ $isWebView: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 20px;
  width: 24px;
  height: 24px;
  background-image: url(${CloseSvg});
  background-repeat: no-repeat;
  background-position: center;
  background-color: transparent;
  transition: all 0.1s ease-out;
  z-index: 99;
  &:hover {
    opacity: 0.8;
  }
  ${props => props.$isWebView && `
    top: 60px;
  `}
`;
export const Title = styled.span`
  text-align: center;
  font-family: "Noticia Text";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(180deg, #FFEAA2 0%, #DB8307 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  user-select: none;
  -webkit-user-select: none;
  margin: 0px 20px 5px;
  cursor: default;
  `;

export const SubTitle = styled.p`
  color:  white;
  cursor: default;
  font-family: "Noticia Text";
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  user-select: none;
  -webkit-user-select: none;
  padding: 0 30px;
`;


export const ContentWrapper = styled.div<{ $appSettings: AppSettingsType }>`
  cursor: default;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${props => props.$appSettings.text_color};
  }
`

export const Decor = styled.div<{ $appMode: AppModes }>`
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  background-image: url('/game/builder/${props => props.$appMode}/lead_decor.webp');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
`
