import { IUserRecordResponse, RedirectConfigType } from '../utils/types';
import { getCookie } from '../utils/encrypt';
import { getGtagId } from '../utils/gtag';

export function recordUserData(
  body: Record<string, any>
): Promise<IUserRecordResponse> {
  return fetch(
    `${process.env.REACT_APP_RECORD_USER_DATA_URL ?? ''}/recordUserDataV2`,
    {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
      },
    }
  ).then((response) =>
    response.status === 200
      ? response.json()
      : Promise.reject(new Error(`Server returned status ${response.status}`))
  );
}

export async function prepareUserDataAndRedirect(config: RedirectConfigType) {
  const searchParams = new URLSearchParams(window.location.search);

  const utm_campaign = searchParams.get('utm_campaign');

  let gtagSessionId = '';
  let gtagClientId = '';

  try {
    gtagSessionId = await getGtagId('session_id');
    gtagClientId = await getGtagId('client_id');
  } catch (e) {
    console.error(e);
  }

  const responseUserRecord = await recordUserData({
    fbc: getCookie('_fbc'),
    fbp: getCookie('_fbp'),
    gtag_client_id: gtagClientId,
    gtag_session_id: gtagSessionId,
    client_user_agent: window.navigator.userAgent,
    timestamp: (Date.now() / 1000).toFixed(),
    action_source: config.redirect_url,
    project: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    bidease_click_id: searchParams.get('bidease_click_id'),
    ...(utm_campaign && { utm_campaign }),
  });

  const mergedParameter = [
    responseUserRecord.result.id,
    process.env.REACT_APP_PIXEL_ID,
    process.env.REACT_APP_FIREBASE_PROJECT_ID,
  ].join('_');

  const referralUrl =
    config.redirect_url.trim() +
    '?' +
    new URLSearchParams({
      subId1: mergedParameter,
      utm_term: mergedParameter,
      utm_affid: process.env.REACT_APP_UTM_AFF_ID ?? '',
    }).toString();

  window.open(referralUrl, '_self');
}
