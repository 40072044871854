import { GameProvider } from './providers/GameProvider';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import useEvents from './hooks/useEvents';
import { ConfigType } from './utils/types';
import Builder from './components/Game/Builder';

function App({ config }: { config: ConfigType }) {
  console.log('3.000024');

  useEvents();

  return (
    <GameProvider config={config}>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/game/" element={<Builder />} />
          <Route path="/" element={<Builder />} />
        </Routes>
      </BrowserRouter>
    </GameProvider>
  );
}

export default App;
