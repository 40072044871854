import * as S from './Leaderboard.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import Leader1Webp from '../../../../assets/builder_images/leader_1.webp';
import Leader2Webp from '../../../../assets/builder_images/leader_2.webp';
import Leader3Webp from '../../../../assets/builder_images/leader_3.webp';
import { useEffect } from 'react';
import { numberWithCommas } from '../../../../utils/number';

export function Leaderboard({ onClose, isWebView }: { onClose: () => void; isWebView: boolean }) {
  const { config, totalWinAmount, appMode, appSettings } = useGameState();

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  const getTable = () => {
    const tableWithUser = [
      ...config.leaderboard,
      {
        name: config.leaderboardUserName,
        score: totalWinAmount,
      },
    ];

    return tableWithUser.sort((a, b) => b.score - a.score);
  };

  const getBgImage = (index: number) => {
    if (index === 0) {
      return Leader1Webp;
    }
    if (index === 1) {
      return Leader2Webp;
    }
    if (index === 2) {
      return Leader3Webp;
    }
    return '';
  };

  return (
    <S.Wrapper>
      <S.Modal $appMode={appMode} $appSettings={appSettings}>
        <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
        <S.Title $appSettings={appSettings} $isWebView={isWebView}>
          {config.leaderboardTitle}
        </S.Title>
        <S.SubTitle>{config.leaderboardSubTitle}</S.SubTitle>
        <S.TableHeader $appSettings={appSettings}>
          <div>Rank</div>
          <div>User</div>
          <div>Total earnings</div>
        </S.TableHeader>
        <S.TableWrapper $appSettings={appSettings}>
          <S.Table>
            {getTable().map((el, index) => (
              <S.Row
                $appSettings={appSettings}
                $appMode={appMode}
                $backImg={getBgImage(index)}
                className={el.name === config.leaderboardUserName ? 'selected' : undefined}
                key={el.name}
              >
                <div>{index + 1}</div>
                <div>{el.name}</div>
                <div style={{ fontFamily: appSettings.font_family ?? 'Noticia Text' }}>
                  <S.Coin className="coin" $appMode={appMode} />
                  {numberWithCommas(el.score)}
                </div>
              </S.Row>
            ))}
          </S.Table>
        </S.TableWrapper>
        <S.Decor $appMode={appMode}></S.Decor>
      </S.Modal>
    </S.Wrapper>
  );
}
