import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

export function AgeVerification({ onClose }: { onClose: () => void }) {
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 101,
        width: '100%',
        height: '100%',
        alignItems: isMobile ? 'end' : 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(3, 5, 7, 0.85)',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          maxWidth: '480px',
          borderRadius: isMobile ? undefined : '20px',
          borderTopLeftRadius: isMobile ? '20px' : undefined,
          borderTopRightRadius: isMobile ? '20px' : undefined,
        }}
      >
        <Box
          sx={{
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Noticia Text',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
            }}
          >
            Are you over 18 years old?
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Noticia Text',
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: 'normal',
              color: 'rgba(0, 0, 0, 0.40)',
              marginTop: '10px',
              marginBottom: '20px',
              textAlign: 'center',
              userSelect: 'none',
            }}
          >
            To continue, confirm that you have reached the legal age
          </Typography>
          <Box
            sx={{
              display: 'flex',
              gap: '10px',
            }}
          >
            <Box
              onClick={() => {}}
              sx={{
                background: 'rgba(0, 0, 0, 0.40)',
                borderRadius: '12px',
                height: '56px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontFamily: 'Noticia Text',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: 'normal',
                userSelect: 'none',
                transition: 'all 0.3s ease',
                color: '#fff',
                '&:hover': {
                  opacity: isMobile ? 1 : 0.8,
                },
              }}
            >
              I am under 18
            </Box>
            <Box
              onClick={onClose}
              sx={{
                background: '#00B75F',
                borderRadius: '12px',
                height: '56px',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                fontFamily: 'Noticia Text',
                fontSize: '20px',
                fontWeight: 700,
                lineHeight: 'normal',
                userSelect: 'none',
                transition: 'all 0.3s ease',
                color: '#fff',
                '&:hover': {
                  opacity: isMobile ? 1 : 0.8,
                },
              }}
            >
              I am over 18
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
