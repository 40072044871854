import * as S from './HowToPlay.styles';
import { useGameState } from '../../../../providers/GameProvider.hooks';
import { useMemo } from 'react';
import { Box } from '@mui/material';

export function HowToPlay({ onClose, isWebView }: { onClose: () => void; isWebView: boolean }) {
  const { appMode, appSettings, config } = useGameState();

  const modalTexts = useMemo(() => {
    if (config.plinkoConfig !== undefined) {
      switch (appMode) {
        case 'tiger': {
          return {
            howToPlayTitle: 'Tiger',
            howToPlayText: [
              'Embark on a mystical journey with Fortune Tiger, a game where the allure of the Orient meets the unpredictability of chance, creating an experience filled with the enchantment of watching a ball navigate through a tapestry of challenges. At the heart of Fortune Tiger, anticipation builds as the ball finds its resting place in one of the many sacred holes down the pyramid, each promising to multiply your initial wager, offering outcomes as diverse as the game is thrilling.',
              '🌿 Find yourself on a pyramid-shaped board, its layers adorned with offset rows of pegs, set against a backdrop inspired by the rich textures and colors of the Orient. This is where destiny and fortune intertwine.',
              "🐯 Nestled at the pyramid's base are 'holes', each associated with a coefficient and awaiting the ball's touch. Fortune smiles where it lands, with your wager set to increase according to the chosen hole's value. ",
              "▶️ To start, simply press the play button, sending the ball on a descent from the pyramid's apex. Watch in wonder as it embarks on a serendipitous journey through the pegs, with its final destination unveiling the treasures that lie in wait.",
              'Fortune Tiger invites you to a world where chance and Oriental enchantment converge, designed solely for the purpose of entertainment. This game ensures a venture into the realms of possibility without the risks, as it operates on a virtual currency system. No real money is ever involved, ensuring that the stakes are high only in excitement and not in consequence. ',
              'Welcome to Fortune Tiger, your portal to a universe of captivating, risk-free fun.',
            ],
          };
        }
        case 'olympus': {
          return {
            howToPlayTitle: 'Gates of Olympus',
            howToPlayText: [
              'Step into the divine realms of Gates of Olympus, a game where ancient myths and the thrill of chance unite, offering a spectacle as a ball maneuvers through a celestial maze crafted by the gods. This game captures the essence of suspense and surprise, with each descent of the ball through the godly pyramid determining the multiplication of the player’s initial wager, leading to outcomes filled with divine favor and unpredictability.',
              '✨ Behold a pyramid-shaped board, its surface graced with rows of pegs, acting as the battleground where fate and divine will intersect.',
              "⚡️At the base of the pyramid, sacred ‘holes’ await, each endowed with coefficients; the gods alone know where the ball will rest, with your initial ‘stake’ destined to be multiplied by the gods' whim.",
              '▶️ To start, press the play button, releasing the ball from the highest point of the pyramid. Observe as it embarks on a heavenly journey through the pegs, each bounce a step closer to unveiling the blessings that lie at the journey’s end.',
              'Important: Gates of Olympus promises a risk-free journey through the pantheon of chance, employing virtual currency for all stakes and rewards. With no real money at stake, wagered, or awarded, it ensures a sanctuary of exhilarating, yet safe, divine entertainment.',
              'Welcome to the mythical blend of chance and excitement, all set against the backdrop of the majestic Mount Olympus, crafted purely for entertainment!',
            ],
          };
        }
        case 'book': {
          return {
            howToPlayTitle: 'Book of Dead',
            howToPlayText: [
              'Embark on an ancient journey with Book of Dead, where the mysteries of ancient Egypt and the excitement of discovery come together in a captivating game of chance. As a ball winds its way down a board laden with obstacles, each drop is a chance to multiply your score and uncover surprises hidden within the sands of time.',
              '📜 The board is dotted with pegs that guide the ball on its quest through pyramids and tombs, setting the scene for an epic adventure.',
              "🏺 At the bottom of the board, 'holes' marked with numbers await, each representing a different value; it's a leap of faith to see where the ball will land. Each hole has the potential to multiply your initial stake, mirroring the discovery of treasures long buried.",
              '🌞 Ready to explore the secrets of the pharaohs? Press the start button and release the ball from the top. Watch with bated breath as it navigates through the maze of pegs, each bounce bringing you closer to uncovering the wealth of the ancient world.',
              'Welcome to this easy-to-play, enthralling game, crafted for explorers and thrill-seekers alike!',
              "Important: Book of Dead is designed for fun and safe exploration, using virtual currency for all bets and wins. There's no real money involved, so you can delve into the ancient mysteries without any real-world risk.",
            ],
          };
        }
        case 'starburst': {
          return {
            howToPlayTitle: 'Starburst',
            howToPlayText: [
              'Take off into the cosmic world of Starburst, a game that combines the thrill of the unknown with the excitement of a journey through space. As the ball descends through a board filled with obstacles, each drop is a chance to multiply your score and bring surprises from across the galaxy.',
              '🌌 Picture a board that looks like the night sky, sprinkled with pegs that resemble stars and planets, guiding the ball on its celestial journey. This board is a gateway to the universe, with vibrant colors and cosmic themes that evoke the beauty of outer space.',
              "💫 At the bottom of the board, 'holes' await, each marked with numbers that could boost your initial bet. It's all about luck and timing to see where the ball lands, with each hole representing a potential starburst of winnings.",
              '🚀 To embark on your space adventure, hit the start button and watch the ball launch from the top. Follow its trajectory with excitement as it bounces off the pegs, each turn a step closer to revealing the cosmic prizes that lie in wait.',
              'Welcome to this simple, yet thrilling game, designed for those who dream of the stars!',
              'Important: Starburst ensures a fun-filled journey through the cosmos without any risk, using virtual currency for stakes and rewards. With no real money at stake, you can enjoy the thrill of exploration and discovery in complete safety.',
            ],
          };
        }
        case 'gonzo': {
          return {
            howToPlayTitle: "Gonzo's Quest",
            howToPlayText: [
              "Set sail for the thrilling world of Gonzo's Quest, where adventure and the spirit of discovery fuse in an exciting game of chance. Watch as a ball makes its way through a board brimming with obstacles, each drop a chance to multiply your score and unveil surprises at every turn.",
              '🗺️ The board is filled with pegs that guide the ball on its journey of exploration. This board is a tribute to adventurers and ancient civilizations, with Gonzo leading the way to uncover hidden treasures.',
              "💎 At the bottom of the board, special 'holes' await, each marked with numbers that could increase your initial bet. It’s a game of luck to see where your ball will land, with each hole representing a potential treasure trove to boost your winnings.",
              '🧭 Set off on your quest by hitting the start button, releasing the ball from the top of the map. Follow its path with anticipation as it bounces through obstacles, each turn a step closer to revealing the riches that await at the end of your adventure.',
              'Welcome to this simple-to-play, thrilling game, designed for adventurers at heart!',
              "Important: Gonzo's Quest prioritizes fun and safety, using virtual currency for stakes and rewards. With no real money involved, you can immerse yourself in the excitement of discovery without any financial risk.",
            ],
          };
        }
        case 'spellbinding': {
          return {
            howToPlayTitle: 'Spellbinding Mystery',
            howToPlayText: [
              "Dive into the fun world of Spellbinding Mystery, a game where magic meets luck. It's like playing a game where you watch a ball bounce around a board filled with obstacles. The exciting part is seeing if the ball lands in a special spot that multiplies what you put in, making the game full of surprises.",
              '🪄 A board is shaped like a triangle, covered in pegs that act like little bumps for the ball to hit as it falls. This board is where the magic happens, with magicians and runes adding to the fun.',
              "🧙 At the bottom of the board, there are special spots called 'holes' with different values; it’s all about luck where the ball ends up. If it lands in one of these, your initial amount gets multiplied by the number in that spot.",
              '▶️ To start the game, just press a button and watch the ball go from the top of the triangle. You get to see it hit the pegs randomly and find its way down, hoping it lands in a spot that gives you a big win.',
              'Welcome to this easy-to-play, exciting game designed just for fun!',
              "Important: Spellbinding Mystery is all about safe play. It uses virtual currency, so there's no real betting or risk. It's a great way to have fun without worrying about losing any real cash.",
            ],
          };
        }
        case 'buffalo': {
          return {
            howToPlayTitle: 'Buffalo King',
            howToPlayText: [
              'Jump into to the adventurous world of Buffalo King, where the Wild West meets luck in an exciting game. Watch as a ball tumbles through a board filled with obstacles, aiming for a winning spot that could multiply your score, making every round a thrilling surprise.',
              '🦬 A board is shaped like a wide-open plain, scattered with pegs that mimic the unpredictability of the Wild West.  Themed with majestic buffalo spirit, the board sets the stage for an untamed adventure.',
              "🌵 At the base, you'll find special 'holes' labeled with numbers representing different values; it's all about luck and seeing where the ball will settle. Landing in one of these boosts your staked amount by the value of that hole.",
              "🤠 Ready to take on the frontier? Press the start button and release the ball from the top. It's a wild journey watching it bounce off the pegs, navigating its way down to potentially land you a big win.",
              'Welcome to this easy-going, fun-filled game, crafted for pure enjoyment!',
              "Important: Buffalo King is all about fun without the fuss, using in-game virtual currency for all bets and wins. There's no real money on the line, so you can enjoy the thrill of the game without any real-world worries.",
            ],
          };
        }
        case 'sugar': {
          return {
            howToPlayTitle: 'Sugar Rush',
            howToPlayText: [
              'Jump into the sweet world of Sugar Rush, where the excitement of a game of chance blends with the delightful charm of a candy-filled adventure. As you release the ball onto a board brimming with obstacles, each drop is an opportunity to multiply your score and unwrap surprises that are as tempting as the confections themselves.',
              '🍬 The board - a wonderland of sweets, filled with pegs that will guide the ball through a deliciously decorated landscape.',
              "🍭 At the bottom, 'holes' await, each labeled with numbers that represent different multipliers; where the ball lands is a matter of luck and sweet anticipation. Each destination has the potential to sweeten your initial bet, much like finding your favorite piece of candy in a mix.",
              '🎈 Ready to indulge in this sugary adventure? Press the start button and release the ball from the top. Watch with delight as it bounces through the candy pegs, each bounce a step closer to uncovering the sweet rewards that await at the end of your journey.',
              'Welcome to this easy-to-play, delightful game, perfect for anyone with a sweet tooth or in search of a lighthearted escape!',
              'Important: Sugar Rush is designed for a risk-free, fun-filled escapade into a world of sweets, using virtual currency for all bets and winnings. There’s no real money involved, ensuring a guilt-free enjoyment of all the sweetness without any of the risk.',
            ],
          };
        }
        case 'divine': {
          return {
            howToPlayTitle: 'Divine Fortune',
            howToPlayText: [
              'Venture into the realm of myths with Divine Fortune, where the allure of Ancient Greece and the thrill of adventure unite in an enthralling game of chance. As the ball makes its journey down a board filled with pegs, each drop offers the chance to amplify your score and unveil wonders veiled by the mists of time.',
              "🐍 The board is strewn with pegs that navigate the ball all the way down, setting the stage for a legendary odyssey. Whether Medusa's gaze hinders your score, or Athena's wisdom guides you to greater rewards - only gods now.",
              "🏛 At the board's base, 'holes' await, each signifying a different multiplier; it's a game of fate to see where your ball will settle. Each symbol holds the power to boost your initial stake, akin to unearthing hidden treasures of the mythical past.",
              '⚡️ Strike the start, releasing the ball from on high. Hold your breath as it dances through a labyrinth of pegs, each ricochet drawing you nearer to the fortunes that lie hidden within the ancient tales.',
              'Step into this simple yet mesmerizing game, designed for adventurers and seekers of fortune alike!',
              "Important: Divine Fortune is crafted for entertainment and exploration within a safe realm, employing virtual currency for all stakes and rewards. With no real money at play, you're free to immerse in the legends of Greece without facing real-world stakes.",
            ],
          };
        }
        default: {
          return {
            howToPlayTitle: 'Hook of Era',
            howToPlayText: [
              'Dive into the captivating world of Hook of era, a game that combines the thrill of chance with the simple fascination of watching a ball navigate a maze of obstacles. The essence of the game lies in those critical moments, as the hole down the pyramid where the ball rests multiplies the player’s initial stake, culminating in a win that is as variable and leaves room for surprises.',
              '💫 a pyramid-shaped board adorned with several offset rows of pegs, standing as the battleground where luck and fortune collide.',
              '💰 at the bottom, there are ‘holes’ with coefficients; the ball may end up in any of it, with luck. And your initial ‘stake’ will be multiplied accordingly.',
              '▶️ to start, press the button for the ball to descend into one of the holes boasting different coefficient. As you release the ball from the apex of the pyramid, observe its random bounce through the pegs, and discover the final prize it unveils at the journey’s end. ',
              'Welcome to a blend of randomness and excitement, designed purely for entertainment!',
              'Important: Hook of Era guarantees a risk-free adventure into the realms of chance, ensuring that only a virtual currency is used for stakes and rewards. No real money is ever at stake, wagered, or awarded, thus providing a safe haven of exhilarating entertainment.',
            ],
          };
        }
      }
    } else {
      switch (appMode) {
        case 'tiger': {
          return {
            howToPlayTitle: 'Fortune Tiger',
            howToPlayText: [
              'Embark on a mystical journey through ancient Asia with Fortune Tiger — an immersive, risk-free game that transports you to a world filled with ancient mysteries. Embark with an initial bounty of 300,000 points, and harness the power of sacred symbols to ascend the ranks of the Oriental Leaderboard.',
              '🐉 Unleash the power of the tiger by matching 2 symbols for a reward of 20,000 points; align 3 for a legendary 100,000 points!',
              '🏅 Experience the thrill of discovery with daily rewards that enhance your journey.',
              '💬 Join forces with other adventurers, exchanging tales and strategies.',
              'Note: Fortune Tiger is designed solely for entertainment. No real money is used, risked, or awarded—immersing you in safe, exhilarating fun amidst the legends of Asia.',
              'Are you ready to embark on a risk-free odyssey through the storied landscapes, right to the heart of the East? Unlock the legends of fortune!',
            ],
          };
        }
        case 'olympus': {
          return {
            howToPlayTitle: 'Gates of Olympus',
            howToPlayText: [
              'Set off on a glorious adventure to the storied heights of Mount Olympus with Gates of Olympus, a captivating slot simulation that offers a plunge into the heart of Greek mythology, all without any real-world risk. With a starting treasure trove of 300,000 points, players are invited to harness the ancient symbols of power to scale the Olympian heights on the leaderboard.',
              '💎 Channel the power of the gods by scoring 2 identical gem stones for a celestial bounty of 20,000 points; align 3 for a majestic haul of 100,000 points!',
              '🎉 On top of the thrill of the journey down to ancient times, daily bonuses add extra excitement to this adventure.',
              '🔝 Band together with other heroes, exchanging epic tales and sage advice.',
              'Please note: Gates of Olympus is designed entirely for entertainment purposes. It operates in a virtual currency system, ensuring that no real money is ever at stake, wagered, or awarded, thus providing a safe haven of exhilarating entertainment set against the backdrop of Greek legend.',
              'Are you ready to embark on a journey through the legendary landscapes of the gods, free from risk? Discover the ancient tales of power and glory!',
            ],
          };
        }
        case 'book': {
          return {
            howToPlayTitle: 'Book of Dead',
            howToPlayText: [
              `Journey back to the golden sands of Ancient Egypt in Book of Dead, a slots simulator where you'll navigate through the echoing chambers of history. Your adventure begins with a bountiful supply of 300,000 points, guiding you to master the symbols that unlock the secrets of the ancients on your way to the top of the Pharaoh's Leaderboard.`,
              "🌞 Harness the Sun God's favor by aligning 2 symbols for a radiant gain of 20,000 points; gather 3 to unveil a pharaoh's ransom of 100,000 points!",
              '🐍 Accept daily rewards by the guardians of the tomb for your daring.',
              '📖 Form a caravan of knowledge with like-minded treasure seekers, uncovering the paths to untold riches.',
              'Important note: Book of Dead is an odyssey designed for the spirit of adventure that uses in-game virtual currency, free from any real monetary transactions. No real money is ever at stake, wagered, or awarded in this expedition of excitement and discovery, ensuring a safe entertainment experience.',
              'Prepare to step into the shoes of the greatest explorers of yore, decoding the mysteries that have slumbered beneath the stars of the Nile. Your legend awaits in the shadows of the pyramids!',
            ],
          };
        }
        case 'starburst': {
          return {
            howToPlayTitle: 'Starburst',
            howToPlayText: [
              'Launch into a cosmic voyage with Starburst, a dazzling slot simulator that propels you into the vastness of space, where every spin is a risk-free journey among the stars. Your expedition kicks off with a luminous stash of 300,000 points, inviting you to align the shimmering gemstones and cosmic symbols to ascend through the cosmic leaderboard.',
              "✨ Capture the essence of stardust by matching 2 gemstones for a glimmering prize of 20,000 points; align 3 to harvest a constellation's worth of 100,000 points!",
              '🌌 Navigate through the galaxy with daily missions that illuminate your path with additional sparkling rewards.',
              '🌠 Join a fellowship of interstellar adventurers, sharing maps of the skies and guiding each other towards astronomical treasures.',
              'Important note: "Starburst" is crafted for the sheer joy of exploration and wonder, operating without use of any real money. Only the virtual in-game currency is in use; no real money is ever at stake, wagered, or awarded on this journey of thrilling entertainment amidst the beauty of the cosmos.',
              'Are you ready to embark on an intergalactic odyssey, to weave through the tapestry of the night sky in search of the radiant heart of the universe? Your adventure among the stars begins now!',
            ],
          };
        }
        case 'gonzo': {
          return {
            howToPlayTitle: "Gonzo's Quest",
            howToPlayText: [
              "Welcome to Gonzo's Quest, a thrilling slot simulator that invites you to step into the boots of a medieval adventurer, charting a course through uncharted territories in search of forgotten treasures. With a starting kit of 300,000 points, you are set to navigate through the dense jungles of the New World, deciphering ancient symbols and aligning mysterious totems to rise up the ranks of the Adventurer's Leaderboard.",
              "🗺️ Follow Gonzo's map by matching 2 symbols for a discovery reward of 20,000 points; line up 3 to unearth a hidden city's fortune of 100,000 points!",
              '🌿 Tread through the thicket with daily rewards that will guide you on your voyage.',
              '🏰 Forge alliances with fellow questers, sharing tales of daring and strategies for survival.',
              "Please note: Gonzo's Quest is an adventure crafted for the thrill of discovery and does not involve any real money. No real money is ever at stake, wagered, or awarded in this journey through time and imagination, ensuring a safe yet exhilarating quest for glory and riches in the spirit of medieval exploration.",
              "Are you ready to don your explorer's hat and set off on a quest filled with danger, mystery, and the chance to write your name in the annals of history? Your legend awaits!",
            ],
          };
        }
        case 'spellbinding': {
          return {
            howToPlayTitle: 'Spellbinding Mystery ',
            howToPlayText: [
              "Step into a world woven with magic and intrigue in Spellbinding Mystery, a slot simulator that beckons you into the shadows of the unknown, where every spin uncovers a piece of the arcane. Begin your journey with a mystical gift of 300,000 points, and align the enchanted symbols to ascend the ranks of the Arcanist's Leaderboard.",
              '🔮 Harness the ancient energies by matching 2 symbols for a burst of 20,000 points; align 3 to unlock a treasure of 100,000 points shrouded in sorcery!',
              '🌙 Navigate through the veil of night with daily challenges that reward your bravery with mystical artifacts and points.',
              '📚 Forge a fellowship with other seekers of the hidden, sharing spells and wisdom to unravel the mysteries that lie dormant.',
              'Please be aware: Spellbinding Mystery is an adventure crafted in the cauldrons of imagination, intended purely for the thrill of the chase and the joy of discovery.  Only the virtual in-game currency is in use; no real money is ever at stake, wagered, or awarded, allowing you to immerse yourself in the quest for arcane knowledge and ancient secrets without concern.',
              'Are you ready to cast yourself into the depths of enchantment, to decipher the riddles and spells that have bewitched many before you? Your magical quest begins now, under the watchful eye of the moon and stars.',
            ],
          };
        }
        case 'buffalo': {
          return {
            howToPlayTitle: 'Buffalo King',
            howToPlayText: [
              'Embark on a majestic journey into the heart of the wild canyons with Buffalo King, a slot simulator that transports you to the untamed landscapes of the American West. Your adventure begins with a robust reserve of 300,000 points, inviting you to explore the rugged beauty of nature while aligning the symbols of the wilderness to climb the ranks of the Frontier Leaderboard.',
              "🦬 Summon the spirit of the buffalo by matching 2 symbols for a rugged reward of 20,000 points; bring together 3 to claim a king's bounty of 100,000 points amidst the echoes of the canyons!",
              '🌄 Traverse the vast expanses with daily rewards for your pioneering spirit that sustain you along your journey into the secrets of the wild.',
              '🦅 Join a pack of fellow adventurers, sharing tales of trails blazed and strategies for survival in the wilderness.',
              'Please be aware: Buffalo King is an odyssey designed for those who seek the thrill of discovery in the wild realms of nature, entirely without the risks associated with real-world finances. Only the virtual in-game currency is in use; no real money is ever at stake, wagered, or awarded. Indulge in the pursuit of awe and adventure among the canyons and vast landscapes, free from concern.',
              'Are you prepared to heed the call of the wild, to venture into the depths of the canyons in search of the treasures that lie hidden beneath the starlit skies? Your adventure amidst the untamed beauty of nature starts now.',
            ],
          };
        }
        case 'sugar': {
          return {
            howToPlayTitle: 'Sugar Rush',
            howToPlayText: [
              'Dive into a whimsical world of sweet delights with Sugar Rush, a slots simulator that envelops you in a candy-coated universe where every spin is a taste of magic. Embark on this delicious adventure with an initial stash of 300,000 points, inviting you to align confectionery symbols to ascend the ranks of the Candy Leaderboard.',
              '🍬 Match 2 sugary treats for a delightful reward of 20,000 points; align 3 for a scrumptious jackpot of 100,000 points, bursting with flavor!',
              '🍭 Navigate through a world dripping with caramel and chocolate with daily rewards that sweeten your journey.',
              '🍫 Join a fellowship of candy connoisseurs, sharing recipes for success and tales of sugary exploits.',
              'Please note: Sugar Rush is a confectionery quest crafted for the joy of exploration and the thrill of discovery, completely free from real-world financial concerns. Only the virtual in-game currency is in use; no real money is ever at stake, wagered, or awarded.',
              'Are you ready to embark on a flavorful odyssey, to unravel the mysteries of a world where the rivers flow with chocolate and the mountains are made of marzipan? Your sweet adventure begins now, under the twinkling stars of the candy cosmos.',
            ],
          };
        }
        case 'divine': {
          return {
            howToPlayTitle: 'Divine Fortune',
            howToPlayText: [
              'Set off on an adventure to Ancient Greece with Divine Fortune, a slots simulator game that takes you right into the heart of myth and legend. Kick things off with a generous stash of 300,000 points and dive into the challenge of mastering ancient symbols. Your mission is to climb to the top of the Leaderboard, where the gods watch over their champions.',
              "🐍 Summon the power of Medusa with just 2 symbols for a stone-cold gain of 20,000 points; align 3 to unleash a titan's bounty of 100,000 points!",
              '🌟 Get daily gifts from the Olympian gods for your bravery.',
              '🏺 Team up with other adventurers, sharing tips and secrets on how to win big and find the hidden treasures.',
              'Important note: Divine Fortune is all about the thrill of the gaming experience, using virtual currency for all the fun and none of the risk. No real money is ever at risk, wagered, or rewarded in this journey of marvel and exploration, ensuring a secure entertainment experience.',
              'Gear up to walk in the footsteps of the greatest Greek heroes, solving the riddles of the gods under the shadow of the Parthenon. Your legend begins now, with Medusa and the gods of Olympus as your guides!',
            ],
          };
        }
        default: {
          return {
            howToPlayTitle: 'Hook of Era: Game of the Pharaohs',
            howToPlayText: [
              'Delve into ancient Egypt with Hook of Era: Game of the Pharaohs—a risk-free mobile game adventure. Start with a generous 300,000 points and match hieroglyphs to climb the Pyramid Leaderboard.',
              '🎰 Secure 2 matches to gain 20,000 points; 3 matches award you 100,000!',
              '🥇 Daily bonuses keep your journey thrilling.',
              '💬 Connect with fellow explorers and share in the fun.',
              'Note: This game is purely for entertainment. No real money is involved, wagered, or won—pure, safe fun in the world of Pharaohs.',
              '🌞 Ready for a risk-free Egyptian escapade? Download now and play into history! 🐫',
            ],
          };
        }
      }
    }
  }, [appMode, config]);

  return (
    <S.Wrapper>
      <S.Modal $appMode={appMode} $appSettings={appSettings}>
        <S.CloseButton $isWebView={isWebView} onClick={onClose}></S.CloseButton>
        <S.ContentWrapper $appSettings={appSettings}>
          <Box
            sx={{
              backgroundImage: `url('/game/builder/${appMode}/info_icon.webp')`,
              backgroundSize: 'cover',
              minWidth: '235px',
              minHeight: '235px',
              width: '235px',
              height: '235px',
              marginTop: isWebView ? '20px' : '-20px',
              marginBottom: '-50px',
            }}
          ></Box>
          <S.Title>{modalTexts.howToPlayTitle}</S.Title>
          {modalTexts.howToPlayText.map((el, index) => (
            <S.SubTitle key={index}>{el}</S.SubTitle>
          ))}
        </S.ContentWrapper>
        <S.Decor $appMode={appMode}></S.Decor>
      </S.Modal>
    </S.Wrapper>
  );
}
