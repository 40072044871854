import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useGameState } from '../../providers/GameProvider.hooks';
import Modal from './components/Modal';
import Landing from './components/Landing';

import Leaderboard from './components/Leaderboard';
import { numberWithCommas } from '../../utils/number';
import HowToPlay from './components/HowToPlay';
import VideoMp4 from '../../assets/builder_video/coins.mp4';
import VideoWebm from '../../assets/builder_video/coins.webm';
import m4bPopover from '../../assets/builder_images/popover_image_mix4bet.webp';

import IntroHighlights from './components/IntroHighlights';
import { sendGtagEvent } from '../../utils/sendEvent';
import CookiesAccept from './components/CookiesAccept';
import AgeVerification from './components/AgeVerification';
import { Box, Typography, useMediaQuery } from '@mui/material';
import Disclaimer from './components/Disclaimer';
import { BuilderSlotsMachine } from './components/SlotsMachine/SlotsMachine';
import { BuilderPlinko } from './components/Plinko/Plinko';
import { ANIMATION_DURATION } from '../../providers/GameProvider.types';

function Builder() {
  const {
    totalWinAmount,
    isWinningRound,
    config,
    isShowLanding,
    appMode,
    isShowModal,
    appSettings,
    roundWinAmount,
    showSpinButtonAnimation,
  } = useGameState();
  const [isShowLeaderboard, setShowLeaderboard] = useState(false);
  const [isShowHowToPlay, setShowHowToPlay] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const isLess900 = useMediaQuery('(max-height:900px)');
  const videoRef = useRef<null | HTMLVideoElement>(null);
  const params = new URLSearchParams(window.location.search);
  const isWebView = params.get('webView') === 'true';
  const [isStorageCookiesAccepted, setStorageCookiesAccepted] = useState(
    window.localStorage.getItem(`isCookiesAccepted_${appMode}`) === 'true'
  );
  const [isStorageAgeVerified, setStorageAgeVerified] = useState(
    window.localStorage.getItem(`isAgeVerified_${appMode}`) === 'true'
  );
  const isPlinko = config.plinkoConfig !== undefined;

  useEffect(() => {
    if (isWinningRound) {
      setShowVideo(true);
      if (videoRef.current) {
        videoRef.current.currentTime = 0;
        videoRef.current.play();
      }
      setTimeout(() => {
        setShowVideo(false);
      }, ANIMATION_DURATION);
    }
  }, [isWinningRound]);

  // REMOVE after check
  useLayoutEffect(() => {
    sendGtagEvent('Game machine loaded');
  }, []);

  useEffect(() => {
    const popoverbackgroundImage = new Image();
    popoverbackgroundImage.src = config.m4bPopover ? m4bPopover : `/game/builder/${appMode}/popover.webp`;
    popoverbackgroundImage.onload = () => {
      //  REMOVE after check
      sendGtagEvent('All resources loaded');
    };
  }, [config, appMode]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: `url('/game/builder/${appMode}/back_pattern.webp')`,
        backgroundSize: '100px 100px',
        backgroundPosition: 'center',
        height: '100%',
        overflowX: 'hidden',
      }}
    >
      <Box
        sx={{
          width: '375px',
          minWidth: '375px',
          minHeight: window.innerHeight,
          background: appSettings.bg_color,
          position: 'relative',
          '&:after': {
            backgroundImage: `url('/game/builder/${appMode}/column_pattern.webp')`,
            backgroundSize: '40px 40px',
            position: 'absolute',
            zIndex: 99,
            content: '""',
            display: 'block',
            width: '40px',
            height: '100%',
            top: 0,
            right: '50%',
            transform: 'translateX(-187px)',
          },
          '&:before': {
            backgroundImage: `url('/game/builder/${appMode}/column_pattern.webp')`,
            backgroundSize: '40px 40px',
            position: 'absolute',
            zIndex: 99,
            content: '""',
            display: 'block',
            width: '40px',
            height: '100%',
            top: 0,
            left: '50%',
            transform: 'translateX(187px) rotate(180deg)',
          },
        }}
      >
        <Box
          sx={{
            maxHeight: window.innerHeight,
            height: '900px',
            position: 'relative',
            zIndex: 10,
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: '100%',
              zIndex: 2,
              maxHeight: window.innerHeight,
              height: '900px',
              backgroundImage: isPlinko
                ? `url('/game/builder/${appMode}/plinko_background.webp')`
                : `url('/game/builder/${appMode}/slots_background.webp')`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'bottom 30px center',
            }}
          ></Box>
          {config.spinButtonAnimation && showSpinButtonAnimation && <IntroHighlights />}
          <Box
            sx={{
              width: '72px',
              height: '50px',
              backgroundImage: `url('/game/builder/${appMode}/info_btn.webp')`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              cursor: 'pointer',
              position: 'absolute',
              WebkitTapHighlightColor: 'transparent',
              bottom: 40,
              zIndex: 5,
              left: 35,
              opacity: config.spinButtonAnimation && showSpinButtonAnimation ? 0.3 : 1,
              transition: 'all 0.3s ease',
            }}
            onClick={() => {
              setShowHowToPlay(true);
            }}
          ></Box>
          <Box
            sx={{
              position: 'absolute',
              bottom: '153px',
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              zIndex: 5,
            }}
          >
            <img src={`/game/builder/${appMode}/balance_icon.webp`} width={14} height={15} alt="coin"></img>
            <Typography
              sx={{
                fontFamily: 'Avenir Next Condensed',
                fontSize: '16px',
                color: '#fff',
                fontWeight: 600,
              }}
            >
              {numberWithCommas(totalWinAmount)}
            </Typography>
          </Box>
          <Box
            sx={{
              width: '72px',
              height: '50px',
              backgroundImage: `url('/game/builder/${appMode}/lead_btn.webp')`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              cursor: 'pointer',
              position: 'absolute',
              WebkitTapHighlightColor: 'transparent',
              zIndex: 5,
              bottom: 40,
              right: 35,
              opacity: config.spinButtonAnimation && showSpinButtonAnimation ? 0.3 : 1,
              transition: 'all 0.3s ease',
            }}
            onClick={() => {
              setShowLeaderboard(true);
            }}
          ></Box>
          {config.plinkoConfig ? <BuilderPlinko></BuilderPlinko> : <BuilderSlotsMachine></BuilderSlotsMachine>}
          <Box
            sx={{
              position: 'absolute',
              bottom: isLess900 ? 192 : undefined,
              top: isLess900 ? undefined : 671,
              zIndex: 30,
              right: '50%',
              WebkitTapHighlightColor: 'transparent',
              transform: 'translateX(50%)',
            }}
          >
            <Box
              sx={{
                animation: isWinningRound ? 'fadeIn 0.7s alternate infinite ease-out' : undefined,
              }}
            >
              <Typography
                sx={{
                  position: 'absolute',
                  fontFamily: appSettings.font_family ?? 'Noticia Text',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '1.5',
                  background: 'linear-gradient(180deg, #FFEAA2 0%, #DB8307 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                {roundWinAmount && `Win ${numberWithCommas(roundWinAmount)}`}
              </Typography>
              <Typography
                sx={{
                  top: 0,
                  fontFamily: appSettings.font_family ?? 'Noticia Text',
                  fontSize: '24px',
                  fontWeight: 700,
                  lineHeight: '1.5',
                  textShadow: '0px 2px 0px rgba(0, 0, 0, 0.40)',
                }}
              >
                {roundWinAmount && `Win ${numberWithCommas(roundWinAmount)}`}
              </Typography>
            </Box>
          </Box>
        </Box>
        {config.showDisclaimer && <Disclaimer></Disclaimer>}
      </Box>

      {isShowModal && <Modal />}

      {isShowLanding && <Landing />}

      {isShowLeaderboard && (
        <Leaderboard
          isWebView={isWebView}
          onClose={() => {
            setShowLeaderboard(false);
          }}
        />
      )}

      {isShowHowToPlay && (
        <HowToPlay
          isWebView={isWebView}
          onClose={() => {
            setShowHowToPlay(false);
          }}
        />
      )}

      {config.showCookies && !isStorageCookiesAccepted && (
        <CookiesAccept
          onClose={() => {
            window.localStorage.setItem(`isCookiesAccepted_${appMode}`, 'true');
            setStorageCookiesAccepted(true);
          }}
        />
      )}

      {config.showAgeVerification && !isStorageAgeVerified && !(config.showCookies && !isStorageCookiesAccepted) && (
        <AgeVerification
          onClose={() => {
            window.localStorage.setItem(`isAgeVerified_${appMode}`, 'true');
            setStorageAgeVerified(true);
          }}
        />
      )}

      <Box
        sx={{
          display: showVideo ? 'block' : 'none',
          position: 'fixed',
          zIndex: 99,
          bottom: 0,
          transform: 'translateY(4px)',
        }}
      >
        <video autoPlay ref={videoRef} muted playsInline preload="auto">
          <source src={VideoMp4} type='video/mp4; codecs="hvc1"'></source>
          <source src={VideoWebm} type="video/webm"></source>
        </video>
      </Box>
    </Box>
  );
}

export default Builder;
