import { useGameState } from '../../../../providers/GameProvider.hooks';
import { useEffect, useRef, useState } from 'react';
import { getPlinkoRandomNumber } from '../../../../utils/number';
import { Box, useMediaQuery } from '@mui/material';
import Matter, { Engine, Render, Runner, Bodies, Composite } from 'matter-js';

export function BuilderPlinko() {
  const isLess900 = useMediaQuery('(max-height:900px)');
  const isMobile = useMediaQuery('(max-width:480px)');
  const { onFinish, config, roundNumber, onStart, isRolling, appMode } = useGameState();
  const [stateBall, setStateBall] = useState<Matter.Body | null>(null);
  const plinkBoxRef = useRef<HTMLElement | null>(null);
  const effectsBox = useRef<HTMLElement | null>(null);

  const worldWidth = 320;
  const worldHeight = 306;

  const addEffect = (x: number, y: number) => {
    if (effectsBox.current) {
      const effect = document.createElement('div');
      effect.classList.add('collisionEffect');
      effect.style.top = `${y - 15}px`;
      effect.style.left = `${x - 14}px`;
      effectsBox.current.append(effect);
    }
  };

  const finishHandler = (event: Matter.IEvent<Matter.Body>) => {
    const finishMultiplier = () => {
      const bFP = event.source.position.x;
      switch (true) {
        case bFP > 20 && bFP < 60: {
          return 2;
        }
        case bFP > 60 && bFP < 100: {
          return 1.5;
        }
        case bFP > 100 && bFP < 140: {
          return 1;
        }
        case bFP > 140 && bFP < 180: {
          return 0.5;
        }
        case bFP > 180 && bFP < 220: {
          return 1;
        }
        case bFP > 220 && bFP < 260: {
          return 1.5;
        }
        case bFP > 260 && bFP < 320: {
          return 2;
        }
        default: {
          return 1;
        }
      }
    };
    if (config.plinkoBetValue) {
      const winValue = config.plinkoBetValue * finishMultiplier();
      onFinish(true, winValue);
    }
  };
  var engine = useRef(Engine.create({ enableSleeping: true }));

  const handleClick = () => {
    if (effectsBox.current) {
      effectsBox.current.innerHTML = '';
    }
    if (stateBall) {
      Composite.remove(engine.current.world, stateBall);
    }
    onStart();

    // facebook and google Contact event
    // @ts-ignore
    if (window.fbq) {
      // @ts-ignore
      fbq('track', 'Contact');
    }
    //@ts-ignore
    if (window.gtag) {
      //@ts-ignore
      gtag('event', 'Contact', { send_to: process.env.REACT_APP_GTAG_EVENTS_ID });
    }

    const ballSize = 12;
    const ballElastity = 0.9;

    const getBallStartPosition = (finishCellNumber: number) => {
      const finishPositions = [
        // 0.5x
        [161],
        // 1x
        [155.5],
        // 1.5x
        [157.3],
        // 2x
        [158.5],
      ];
      const result = finishPositions[finishCellNumber - 1][0];
      return result;
    };

    if (config.plinkoConfig) {
      const finishCellNumber = config.plinkoConfig[roundNumber - 1];
      const ballStartPosition = finishCellNumber ? getBallStartPosition(finishCellNumber) : getPlinkoRandomNumber();
      const ball = Bodies.circle(ballStartPosition, 0, ballSize, {
        restitution: ballElastity,
        render: {
          sprite: {
            texture: `/game/builder/${appMode}/plinko_ball.webp`,
            xScale: 0.5,
            yScale: 0.5,
          },
        },
      });
      setStateBall(ball);

      Composite.add(engine.current.world, [ball]);
      // ball.friction = 0.5

      Matter.Events.on(ball, 'sleepStart', finishHandler);
    }
  };

  useEffect(() => {
    if (plinkBoxRef.current) {
      const startPins = 3;
      const pinLines = 6;
      const pinSize = 2.5;
      const pinVerticalGap = 42;
      const pinHorizontalGap = 40;

      var render = Render.create({
        element: plinkBoxRef.current,
        engine: engine.current,
        options: {
          pixelRatio: window.devicePixelRatio,
          width: worldWidth,
          height: worldHeight,
          wireframes: false,
          background: 'transparent',
          showSleeping: false,
        },
      });

      const pins: Matter.Body[] = [];
      for (let l = 0; l < pinLines; l++) {
        const linePins = startPins + l;
        const lineWidth = linePins * pinHorizontalGap;
        for (let i = 0; i < linePins; i++) {
          const pin = Bodies.circle(
            worldWidth / 2 - lineWidth / 2 + i * pinHorizontalGap + 20,
            50 + l * pinVerticalGap,
            pinSize,
            {
              isStatic: true,
              render: {
                sprite: {
                  texture: `/game/builder/${appMode}/plinko_dot.webp`,
                  xScale: 0.5,
                  yScale: 0.5,
                },
              },
            }
          );
          // Matter.Events.on(pin, 'collisionStart', (event)=>{
          //   console.log('collisionStart',event);
          // });

          pins.push(pin);
        }
      }
      Composite.add(engine.current.world, pins);

      const ground = Bodies.rectangle(worldWidth / 2, worldHeight, worldWidth, 5, {
        isStatic: true,
        render: {
          visible: false,
        },
      });
      Composite.add(engine.current.world, [ground]);

      const walls = [
        Bodies.rectangle(0, worldHeight / 2, 5, worldHeight, {
          isStatic: true,
          render: {
            visible: false,
          },
        }),
        Bodies.rectangle(worldWidth, worldHeight / 2, 5, worldHeight, {
          isStatic: true,
          render: {
            visible: false,
          },
        }),
      ];
      Composite.add(engine.current.world, walls);

      for (let v = 0; v < 8; v++) {
        const catchBlock = Bodies.rectangle(v * pinHorizontalGap + pinHorizontalGap / 2, 292, 2, 17, {
          isStatic: true,
          render: {
            visible: false,
          },
        });
        Composite.add(engine.current.world, [catchBlock]);
      }

      // run the renderer
      Render.run(render);

      // create runner
      var runner = Runner.create();

      // run the engine
      Runner.run(runner, engine.current);

      Matter.Events.on(engine.current, 'collisionStart', (event) => {
        if (event.pairs.length > 0) {
          const pairs = [...event.pairs];
          const x = pairs[0].bodyA.position.x;
          const y = pairs[0].bodyA.position.y;
          if (y < 290) {
            addEffect(x, y);
          }
        }
      });
    }
  }, [plinkBoxRef, appMode]);

  return (
    <>
      <Box
        sx={{
          width: '320px',
          height: '306px',
          position: 'absolute',
          bottom: isLess900 ? 235 : undefined,
          top: isLess900 ? undefined : 360,
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundImage: `url('/game/builder/${appMode}/plinko_machine_back.webp')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      >
        <Box
          ref={plinkBoxRef}
          sx={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            zIndex: 2,
          }}
        ></Box>
        <Box
          ref={effectsBox}
          sx={{
            // position: 'absolute',
            // background: 'rgba(0,0,0,0.5)',
            width: '100%',
            height: '100%',
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          pointerEvents: isRolling ? 'none' : undefined,
          width: '120px',
          height: '100px',
          backgroundImage: isRolling
            ? `url('/game/builder/${appMode}/play_pushed.webp')`
            : `url('/game/builder/${appMode}/play.webp')`,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
          cursor: 'pointer',
          position: 'absolute',
          bottom: isLess900 ? 40 : undefined,
          top: isLess900 ? undefined : 760,
          zIndex: 30,
          right: '50%',
          WebkitTapHighlightColor: 'transparent',
          transform: 'translateX(50%)',
          transition: 'all 0.3s ease',
          '&:hover': {
            opacity: isMobile ? 1 : 0.8,
          },
        }}
        onClick={isRolling ? undefined : handleClick}
      ></Box>
    </>
  );
}
