import { Box, Typography } from '@mui/material';
import IntroHighlight from '../../../../assets/builder_images/intro_highlight_back.webp';
import { useGameState } from '../../../../providers/GameProvider.hooks';

export function IntroHighlights() {
  const dotsAnimation = 'highlightDots 1.2s linear infinite';
  const { config } = useGameState();
  const params = new URLSearchParams(window.location.search);
  const isGreece = params.get('cfc') === 'GR';

  return (
    <Box
      sx={{
        position: 'absolute',
        width: '100%',
        zIndex: 9,
        backgroundColor: 'rgba(0, 0, 0, 0.70)',
        bottom: '144.5px',
        top: 0,
        backgroundImage: `url(${IntroHighlight})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center bottom 15px',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          bottom: '125px',
          width: '100%',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: '32px',
              fontWeight: 800,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              background: 'linear-gradient(185deg, #FFF8C9 30.1%, #FFFCEC 51.9%, #FFC55C 84.47%)',
              WebkitBackgroundClip: 'text',
              whiteSpace: 'nowrap',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {config.spinButtonAnimationTitle?.[0]}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: '32px',
              fontWeight: 800,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              whiteSpace: 'nowrap',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            {config.spinButtonAnimationTitle?.[0]}
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: '32px',
              fontWeight: 800,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              background: 'linear-gradient(185deg, #FFF8C9 30.1%, #FFFCEC 51.9%, #FFC55C 84.47%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              whiteSpace: 'nowrap',
            }}
          >
            {config.spinButtonAnimationTitle?.[1]}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: '32px',
              fontWeight: 800,
              whiteSpace: 'nowrap',
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            {config.spinButtonAnimationTitle?.[1]}
          </Typography>
        </Box>

        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginTop: isGreece ? '0px' : '-10px',
            marginBottom: isGreece ? '20px' : undefined,
          }}
        >
          <Typography
            sx={{
              position: 'absolute',
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: isGreece ? '42px' : '72px',
              fontWeight: 800,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              background: 'linear-gradient(185deg, #FFF8C9 30.1%, #FFFCEC 51.9%, #FFC55C 84.47%)',
              WebkitBackgroundClip: 'text',
              whiteSpace: 'nowrap',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {config.spinButtonAnimationTitle?.[2]}
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Barlow Condensed ExtraBold',
              fontSize: isGreece ? '42px' : '72px',
              fontWeight: 800,
              lineHeight: 'normal',
              textAlign: 'center',
              userSelect: 'none',
              whiteSpace: 'nowrap',
              textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
            }}
          >
            {config.spinButtonAnimationTitle?.[2]}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          zIndex: 10,
          bottom: '31px',
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '6px',
          '& .row': {
            display: 'flex',
            gap: '11px',
            justifyContent: 'center',
          },
          '& .dot': {
            width: '5px',
            height: '5px',
            background: '#BA3C4A',
            borderRadius: '50%',
          },
          '& .row1': {
            '& .dot': {
              animation: dotsAnimation,
            },
          },
          '& .row2': {
            '& .dot': {
              animation: dotsAnimation,
              animationDelay: '0.15s',
            },
          },
          '& .row3': {
            '& .dot': {
              animation: dotsAnimation,
              animationDelay: '0.3s',
            },
          },
          '& .row4': {
            '& .dot': {
              animation: dotsAnimation,
              animationDelay: '0.45s',
            },
          },
          '& .row5': {
            '& .dot': {
              animation: dotsAnimation,
              animationDelay: '0.6s',
            },
          },
          '& .row6': {
            '& .dot': {
              animation: dotsAnimation,
              animationDelay: '0.75s',
            },
          },
        }}
      >
        <Box className="row row1">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
        <Box className="row row2">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
        <Box className="row row3">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
        <Box className="row row4">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
        <Box className="row row5">
          <Box className="dot"></Box>
          <Box className="dot"></Box>
          <Box className="dot"></Box>
        </Box>
        <Box className="row row6">
          <Box className="dot"></Box>
        </Box>
      </Box>
    </Box>
  );
}
