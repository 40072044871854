import { useState, ReactNode, createContext, FC, useMemo, useEffect } from 'react';
import { AppSettingsType, AppModes, GameContextType, isModeExists, ANIMATION_DURATION } from './GameProvider.types';
import { ConfigType } from '../utils/types';

export const GameContext = createContext<GameContextType | null>(null);

export const GameProvider: FC<{ config: ConfigType; children: ReactNode }> = ({ config, children }) => {
  const getAppMode = (): AppModes => {
    const params = new URLSearchParams(window.location.search);

    const appMode = params.get('mode');
    if (appMode !== null && appMode !== '' && isModeExists(appMode)) {
      return appMode as AppModes;
    } else {
      if (window.location.href.includes('lookofray.com/game')) {
        return 'tiger';
      } else {
        return 'hookofera';
      }
    }
  };
  const appMode = getAppMode();
  const [totalWinAmount, setTotalWinAmount] = useState<number>(config.startAmount);
  const [roundNumber, setRoundNumber] = useState<number>(1);
  const [isShowModal, setShowModal] = useState(false);
  const [isShowLanding, setShowLanding] = useState(false);
  const [isRolling, setRolling] = useState(false);
  const [roundWinAmount, setRoundWinAmount] = useState<number | null>(null);
  const [isWinningRound, setWinningRound] = useState(false);
  const [showSpinButtonAnimation, setShowSpinButtonAnimation] = useState(true);

  const appSettings: AppSettingsType = useMemo(() => {
    switch (appMode) {
      case 'tiger': {
        return {
          app_name: 'Fortune Tiger',
          main_color: '#FCD506',
          text_color: '#C97A7B',
          bg_color: '#47090B',
          bg_dark_color: '#340303',
        };
      }
      case 'olympus': {
        return {
          app_name: 'Gates of Olympus',
          main_color: '#FFC670',
          text_color: '#A57A66',
          bg_color: '#29150F',
          bg_dark_color: '#1B0D09',
        };
      }
      case 'book': {
        return {
          app_name: 'Book of Dead',
          main_color: '#FFD465',
          text_color: '#A57A66',
          bg_color: '#2D0F08',
          bg_dark_color: '#1B0C09',
        };
      }
      case 'starburst': {
        return {
          app_name: 'Starburst',
          main_color: '#FFD465',
          text_color: '#A882A2',
          bg_color: '#352236',
          bg_dark_color: '#0E0515',
          font_family: 'Righteous',
          font_weight: 400,
        };
      }
      case 'gonzo': {
        return {
          app_name: 'Gonzo`s Quest',
          main_color: '#FFD465',
          text_color: '#A58466',
          bg_color: '#271A11',
          bg_dark_color: '#1A130F',
        };
      }
      case 'spellbinding': {
        return {
          app_name: 'Spellbinding Mystery',
          main_color: '#C7D3FF',
          text_color: '#7E8AB5',
          bg_color: '#0E1447',
          bg_dark_color: '#0B0E2C',
        };
      }
      case 'buffalo': {
        return {
          app_name: 'Buffalo King',
          main_color: '#FFC670',
          text_color: '#A57A66',
          bg_color: '#221018',
          bg_dark_color: '#19040C',
        };
      }
      case 'sugar': {
        return {
          app_name: 'Sugar Rush',
          main_color: '#FFC000',
          text_color: '#9A89CB',
          bg_color: '#160051',
          bg_dark_color: '#150935',
        };
      }
      case 'divine': {
        return {
          app_name: 'Divine Fortune',
          main_color: '#F9CF98',
          text_color: '#96745C',
          bg_color: '#241A15',
          bg_dark_color: '#1A130F',
        };
      }
      // hookofera
      default: {
        return {
          app_name: 'Hook of Era',
          text_color: '#A57A66',
          main_color: '#FFC670',
          bg_color: '#29150F',
          bg_dark_color: '#1B0D09',
        };
      }
    }
  }, [appMode]);

  useEffect(() => {
    document.body.style.backgroundColor = appSettings.bg_color;
    document.title = appSettings.app_name;
    document.querySelector('meta[name="theme-color"]')?.setAttribute('content', appSettings.bg_color);
  }, [appSettings]);

  const onFinish = (isUserWin: boolean, userWinAmount: number) => {
    if (isUserWin) {
      setWinningRound(true);
      setRoundWinAmount(userWinAmount);
      setTotalWinAmount((prev) => prev + userWinAmount);
    }

    setRoundNumber((prev) => (prev += 1));
    if (
      roundNumber === config.firstShow ||
      (roundNumber > config.firstShow && (roundNumber - config.firstShow) % config.showAfter === 0)
    ) {
      // facebook and google Lead event
      // @ts-ignore
      if (window.fbq) {
        // @ts-ignore
        fbq('track', 'Lead');
      }
      // @ts-ignore
      if (window.gtag) {
        // @ts-ignore
        gtag('event', 'Lead', {
          send_to: process.env.REACT_APP_GTAG_EVENTS_ID,
        });
      }

      if (config.useLanding) {
        setShowLanding(true);
      } else {
        setTimeout(() => {
          setShowModal(true);
        }, ANIMATION_DURATION - 300);
      }
    }
    setRolling(false);
  };

  const onStart = () => {
    if (config.plinkoBetValue !== undefined) {
      const betValue = config.plinkoBetValue;
      setTotalWinAmount((prev) => prev - betValue);
    } else {
      setTotalWinAmount((prev) => prev + config.prizes[0]);
    }
    setWinningRound(false);
    setRoundWinAmount(null);
    setRolling(true);
    setShowSpinButtonAnimation(false);
  };

  const value: GameContextType = {
    totalWinAmount,
    onFinish,
    config,
    roundNumber,
    isShowModal,
    isShowLanding,
    setShowModal,
    roundWinAmount,
    onStart,
    isRolling,
    isWinningRound,
    appMode,
    appSettings,
    showSpinButtonAnimation,
  };

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};
