import { ConfigType } from '../utils/types';

export type GameContextType = {
  totalWinAmount: number;
  onStart: () => void;
  onFinish: (isUserWin: boolean, userWinAmount: number) => void;
  config: ConfigType;
  roundNumber: number;
  isShowModal: boolean;
  isShowLanding: boolean;
  isRolling: boolean;
  setShowModal: (state: boolean) => void;
  roundWinAmount: number | null;
  isWinningRound: boolean;
  appMode: AppModes;
  appSettings: AppSettingsType;
  showSpinButtonAnimation: boolean;
};

export type ConfigSet = Record<string, ConfigType>;

export type AppModes =
  | 'hookofera'
  | 'tiger'
  | 'olympus'
  | 'book'
  | 'starburst'
  | 'gonzo'
  | 'spellbinding'
  | 'buffalo'
  | 'sugar'
  | 'divine';

const AppModesArray: AppModes[] = [
  'hookofera',
  'tiger',
  'olympus',
  'book',
  'starburst',
  'gonzo',
  'spellbinding',
  'buffalo',
  'sugar',
  'divine',
];

export const isModeExists = (mode: string) => AppModesArray.find((appMode) => appMode === mode);

export type AppSettingsType = {
  app_name: string;
  main_color: string;
  text_color: string;
  bg_color: string;
  bg_dark_color: string;
  font_family?: string;
  font_weight?: number;
};

export const ANIMATION_DURATION = 2300;
