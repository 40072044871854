import React from 'react';
import { AppModes } from '../../../../providers/GameProvider.types';
import { Box } from '@mui/material';

export const SYMBOL_HEIGHT = 102;
const TOTAL_SYMBOLS = 6;
const MULTIPLIER = SYMBOL_HEIGHT * TOTAL_SYMBOLS * 2;

export class BuilderSpinner extends React.Component<{
  timer: number;
  finishNumber: number;
  onFinish: (value: number) => void;
  isWinningRound: boolean;
  appMode: AppModes;
}> {
  state = {
    position: SYMBOL_HEIGHT / 2,
  };

  spin() {
    const toZero = this.state.position % (SYMBOL_HEIGHT * TOTAL_SYMBOLS);
    const finishAt = this.props.finishNumber * SYMBOL_HEIGHT + SYMBOL_HEIGHT / 2;
    const moveTo = this.state.position + MULTIPLIER - toZero + finishAt;

    this.setState({
      position: moveTo,
    });

    setTimeout(() => {
      let currentPosition = this.state.position % (SYMBOL_HEIGHT * TOTAL_SYMBOLS);
      this.props.onFinish(currentPosition);
    }, this.props.timer);
  }

  render() {
    return (
      <Box
        sx={{
          boxSizing: 'border-box',
          display: 'inline-block',
          height: '612px',
          width: '102px',
          // backgroundColor: 'red',
          backgroundImage: `url('/game/builder/${this.props.appMode}/slots.webp')`,
          backgroundSize: 'cover',
          willChange: 'background-position',
          transition: `${this.props.timer / 1000}s background-position cubic-bezier(.41,-0.01,.43,1.09)`,
          animation: this.props.isWinningRound ? 'fadeIn 0.7s alternate infinite ease-out' : undefined,
          backgroundPositionY: this.state.position,
        }}
      ></Box>
    );
  }
}
