import ReactDOM from 'react-dom/client';

import './index.css';

import configsJson from './assets/configs.json';

import { sendGtagEvent } from './utils/sendEvent';

import {
  isBaseConfigType,
  isRedirectConfigType,
  isLandingConfig,
  ConfigType,
  RedirectConfigType,
  LandingConfigType,
} from './utils/types';

import { prepareUserDataAndRedirect } from './services/requests';

import App from './App';
import Landing from './components/Landing';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

new Promise<ConfigType | RedirectConfigType | LandingConfigType>(
  async (resolve, reject) => {
    try {
      const params = new URLSearchParams(window.location.search);
      const location = params.get('cfc');
      const setName = params.get('key');

      let parsedConfig = configsJson.DEFAULT;
      if (location && location in configsJson) {
        parsedConfig = configsJson[location as keyof typeof configsJson] as any;
      }

      if (setName && setName in parsedConfig) {
        sendGtagEvent('Config loaded', setName);
        resolve(parsedConfig[setName as keyof typeof parsedConfig]);
      } else {
        sendGtagEvent('Config loaded');
        resolve(parsedConfig['default']);
      }
    } catch (error) {
      console.log('Failed to load config', error);
      reject(error);
    }
  }
)
  .then(async (response) => {
    //@ts-ignore
    window.webkit?.messageHandlers?.handler?.postMessage('loadFinished');

    if (isRedirectConfigType(response)) {
      await prepareUserDataAndRedirect(response);
    }

    if (isBaseConfigType(response)) {
      root.render(<App config={response} />);
    }

    if (isLandingConfig(response)) {
      root.render(<Landing config={response} />);
    }

    if (!isRedirectConfigType(response) && !isBaseConfigType(response)) {
      console.log('config not found');
    }
  })
  .catch((err) => console.log('Failed to load', err));
