import { Box, Typography, useMediaQuery } from '@mui/material';
import { useEffect } from 'react';

export function CookiesAccept({ onClose }: { onClose: () => void }) {
  const isMobile = useMediaQuery('(max-width:480px)');

  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    return () => {
      document.body.style.overflowY = 'unset';
    };
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 101,
        width: '100%',
        height: '100%',
        alignItems: isMobile ? 'end' : 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(3, 5, 7, 0.85)',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#fff',
          maxWidth: '480px',
          borderRadius: isMobile ? undefined : '20px',
          borderTopLeftRadius: isMobile ? '20px' : undefined,
          borderTopRightRadius: isMobile ? '20px' : undefined,
        }}
      >
        <Box
          sx={{
            padding: '20px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Noticia Text',
              fontSize: '24px',
              fontWeight: 700,
              lineHeight: 'normal',
              textAlign: 'left',
              userSelect: 'none',
            }}
          >
            Cookies
          </Typography>
          <Typography
            sx={{
              fontFamily: 'Noticia Text',
              fontSize: '15px',
              fontWeight: 400,
              lineHeight: 'normal',
              color: 'rgba(0, 0, 0, 0.40)',
              marginTop: '10px',
              marginBottom: '20px',
              textAlign: 'left',
              userSelect: 'none',
            }}
          >
            To provide you with an enhanced and personalized experience, we use profiling cookies for content and
            advertising tailored specifically to you. By selecting "Accept all Cookies," you contribute to a seamless
            and customized browsing journey. Your privacy and preferences are our priority.
          </Typography>
          <Box
            onClick={onClose}
            sx={{
              background: '#00B75F',
              borderRadius: '12px',
              height: '56px',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              fontFamily: 'Noticia Text',
              fontSize: '20px',
              fontWeight: 700,
              lineHeight: 'normal',
              color: '#fff',
              transition: 'all 0.3s ease',
              userSelect: 'none',
              '&:hover': {
                opacity: isMobile ? 1 : 0.8,
              },
            }}
          >
            Accept all
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
