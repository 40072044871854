export type RedirectConfigType = {
  redirect_url: string;
};

export type ConfigType = {
  startAmount: number;
  firstShow: number;
  showAfter: number;
  closeButtonHidden: boolean;
  titleText: string;
  subTitleText: string;
  leaderboardTitle: string;
  leaderboardSubTitle: string;
  leaderboardUserName: string;
  leaderboard: Array<{ name: string; score: number }>;
  prizes: Record<number, number>;
  claimButtonText: string;
  link: string;
  slotConfig?: number[][];
  plinkoConfig?: number[];
  plinkoBetValue?: number;
  useLanding?: string;
  showDisclaimer?: boolean;
  showAgeVerification?: boolean;
  showCookies?: boolean;
  m4bPopover?: boolean;
  m4bTitle?: string;
  spinButtonAnimation?: boolean;
  spinButtonAnimationTitle?: string[];
};

export interface LandingConfigType {
  referral_link: '';
  background: {
    color: string;
    mobileImageURL: string;
    desktopImageURL: string;
  };
  topLogo: {
    logoURL: string;
    logoHidden: boolean;
  };
  topTag: {
    topTagHidden: boolean;
    text: string;
    textColor: string;
    backgroundColor: string;
    borderColor: string;
    fontStyle: string;
  };
  textBlock1: {
    textBlockHidden: boolean;
    text: string;
    textColor: string;
    fontStyle: string;
    fontSize: string;
  };
  textBlock2: {
    textBlockHidden: boolean;
    text: string;
    textColor: string;
    fontStyle: string;
    fontSize: string;
  };
  textBlock3: {
    textBlockHidden: boolean;
    text: string;
    textColor: string;
    fontStyle: string;
    fontSize: string;
  };
  getBonusButton: {
    buttonHidden: boolean;
    buttonText: string;
    textColor: string;
    fontStyle: string;
    buttonColor: string;
    buttonFontSize: string;
  };
  textBlock4: {
    textBlockHidden: boolean;
    text: string;
    textColor: string;
    fontStyle: string;
    fontSize: string;
  };
}

export function isRedirectConfigType(
  unknownConfig: ConfigType | RedirectConfigType | LandingConfigType
): unknownConfig is RedirectConfigType {
  return typeof (unknownConfig as RedirectConfigType).redirect_url === 'string';
}

export function isBaseConfigType(
  unknownConfig: ConfigType | RedirectConfigType | LandingConfigType
): unknownConfig is ConfigType {
  return typeof (unknownConfig as ConfigType).link === 'string';
}

export function isLandingConfig(
  unknownConfig: ConfigType | RedirectConfigType | LandingConfigType
): unknownConfig is LandingConfigType {
  return typeof (unknownConfig as LandingConfigType).referral_link === 'string';
}

export interface IUserRecordResponse {
  result: {
    id: string;
  };
}
